import { FC, useEffect, ReactNode, useState } from "react";

import parseDecimalNumber from "parse-decimal-number";
import { ThemeUIStyleObject } from "theme-ui";

import { Column } from "src/ui/box";
import { FieldError } from "src/ui/field";
import { Input } from "src/ui/input";

type Props = {
  error?: ReactNode;
  value: any;
  onChange: (value: any) => void;
  type?: "string" | "number";
  disabled?: boolean;
  placeholder?: string;
  sx?: ThemeUIStyleObject;
};

const getValidatedInputValue = (type: "string" | "number", val: string) => {
  // TODO: come back to update this. Make it work for all countries and stuff
  if (type === "number") {
    if (!val || !/^[0-9.,]+$/.test(val) || val.endsWith(".") || val.endsWith(",")) {
      return val;
    } else {
      const withoutCommas = val.replace(/,/g, "");
      const parsedNumber = parseDecimalNumber(withoutCommas);
      if (isNaN(parsedNumber)) {
        return val;
      } else {
        return parsedNumber;
      }
    }
  } else if (type === "string") {
    return val;
  }
};

export const AmountInput: FC<Readonly<Props>> = ({
  error,
  value,
  onChange,
  type = "string",
  placeholder = "amount...",
  disabled = false,
  sx = {},
}) => {
  const [internalValue, setInternalValue] = useState(value);
  useEffect(() => {
    setInternalValue(value);
  }, [value]);

  const isInvalid = type === "number" && internalValue && typeof value !== "number";

  let displayedValue = internalValue;
  if (type === "number" && typeof value === "number") {
    displayedValue = internalValue?.toLocaleString();
  }

  const handleChange = (val) => {
    const newValue = getValidatedInputValue(type, val);
    setInternalValue(newValue);
    onChange(newValue);
  };

  return (
    <Column>
      <Input
        disabled={disabled}
        error={isInvalid || Boolean(error)}
        placeholder={placeholder}
        size="small"
        sx={{ maxWidth: "200px", ...sx }}
        value={displayedValue}
        onChange={handleChange}
      />
      {error && <FieldError error={error} sx={{ fontWeight: "semi", fontSize: 0 }} />}
    </Column>
  );
};
