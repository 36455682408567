import { useEffect, useMemo, FC } from "react";

import { Box, Row, Text } from "@hightouchio/ui";
import { useFlags } from "launchdarkly-react-client-sdk";
import { v4 as uuidv4 } from "uuid";

import { useFormErrorContext } from "src/contexts/form-error-context";
import {
  EventCondition,
  FunnelCondition,
  FunnelValueOptions,
  initialPropertyCondition,
  initialFunnelWindow,
  ConditionType,
  OrCondition,
  AndCondition,
  Condition,
} from "src/types/visual";
import { AddBoxSVG, TimeSVG } from "src/ui/icons/new-icons";

import { useQueryBuilderContext } from "../context/query-builder-context";
import { removePropertySubcondition, updateFunnelPropertySubcondition } from "../utils/condition-builders";
import { formatSubconditions } from "../utils/format-subconditions";
import { isAndOrCondition, isPropertyOrReferencePropertyCondition } from "../utils/type-guards";
import { AttributeSelect } from "./attribute-select";
import { ConditionField, FilterProps, HStack } from "./condition";
import { AndOrToggleButton, XButton, DetailButton } from "./condition-buttons";
import { validateFunnelCondition } from "./condition-validation";
import { WindowFilter } from "./window-filter";

type FunnelFilterProps = FilterProps<FunnelCondition> & { eventCondition: EventCondition };

const performOptions = [
  { label: "Then did perform", value: true },
  { label: "Then did not perform", value: false },
];

export const FunnelFilter: FC<Readonly<FunnelFilterProps>> = (props) => {
  const { appAudienceQueryBuilderValidation } = useFlags();
  const { events: eventsOverride, eventCondition, condition: unformattedCondition, onChange, onRemove } = props;
  const filterId = useMemo<string>(uuidv4, []);

  const { events: allEvents } = useQueryBuilderContext();

  const events = eventsOverride ?? allEvents;

  // Wrap the subconditions in one and/or condition
  const condition = formatSubconditions(unformattedCondition);
  const topLevelSubcondition = condition.subconditions?.[0];
  // Assumption is that event condition will be formatted to always have one And/Or subcondition as a child (if there are subconditions)
  const subconditions = isAndOrCondition(topLevelSubcondition) ? topLevelSubcondition.conditions : [];

  const { getErrors, setFieldError, removeErrors } = useFormErrorContext();
  const filterErrors = getErrors(filterId);

  const eventError = filterErrors?.eventModelId;

  useEffect(() => {
    if (appAudienceQueryBuilderValidation) {
      setFieldError(filterId, validateFunnelCondition(condition));
    } else {
      removeErrors([filterId]);
    }

    return () => {
      removeErrors([filterId]);
    };
  }, [appAudienceQueryBuilderValidation, condition.eventModelId, filterId]);

  const eventName = events?.find(({ id }) => id === eventCondition.relationshipId)?.to_model?.name;

  const eventOptions = events?.map(({ id: relationshipId, name, to_model: { id: eventModelId, name: eventModelName } }) => ({
    label: name ?? eventModelName,
    value: eventModelId,
    relationshipId,
  }));

  const updateWrappingConditionType = () => {
    if (!isAndOrCondition(topLevelSubcondition)) {
      return;
    }

    onChange({
      ...condition,
      subconditions: [
        {
          type: topLevelSubcondition.type === ConditionType.And ? ConditionType.Or : ConditionType.And,
          conditions: topLevelSubcondition?.conditions ?? [],
        },
      ],
    });
  };

  const addSubcondition = () => {
    if (!isAndOrCondition(topLevelSubcondition)) {
      return;
    }

    onChange({
      ...condition,
      subconditions: [
        {
          type: topLevelSubcondition.type,
          conditions: [...topLevelSubcondition.conditions, initialPropertyCondition],
        },
      ],
    });
  };

  const updateSubcondition = (index: number, updates: Condition | OrCondition<Condition> | AndCondition<Condition>) => {
    if (isPropertyOrReferencePropertyCondition(updates)) {
      onChange(updateFunnelPropertySubcondition(index, condition, updates));
    }
  };

  const removeSubcondition = (index: number) => {
    onChange(removePropertySubcondition(index, condition));
  };

  return (
    <>
      <HStack gap={2} sx={{ alignItems: "flex-start" }}>
        <Row align="center" color="text.secondary" whiteSpace="nowrap">
          <AttributeSelect
            options={performOptions}
            searchable={false}
            variant="secondary"
            value={condition.didPerform}
            onChange={(didPerform) => onChange({ ...condition, didPerform })}
          />
        </Row>

        <AttributeSelect
          error={eventError}
          options={eventOptions}
          placeholder="Select an event"
          value={condition.eventModelId}
          onChange={(eventModelId, { relationshipId }) => {
            onChange({
              relationshipId,
              eventModelId,
              subconditions: [],
            });
          }}
        />

        {onRemove && <XButton onRemove={onRemove} />}
      </HStack>
      {condition.window && <WindowFilter condition={condition} eventName={eventName} onChange={onChange} />}

      {subconditions?.map((subcondition, index) => {
        if (!isAndOrCondition(topLevelSubcondition)) {
          return null;
        }

        const funnelColumns = events?.find(({ id }) => id === condition.relationshipId)?.to_model?.filterable_audience_columns;
        const referenceColumns = events?.find(({ id }) => id === eventCondition.relationshipId)?.to_model
          ?.filterable_audience_columns;

        return (
          <Row key={index} sx={{ alignItems: "flex-start", pl: 10 }}>
            {index === 0 && (
              <Box alignSelf="flex-start" mt={1.5} mr={2}>
                <Text color="text.secondary">Where</Text>
              </Box>
            )}
            {index !== 0 && (
              <AndOrToggleButton
                hideIcon
                ml={2}
                conditionType={topLevelSubcondition.type}
                size="md"
                onClick={updateWrappingConditionType}
              />
            )}
            <AttributeSelect
              options={FunnelValueOptions}
              sx={{ mr: 2, borderColor: eventError ? "red" : undefined }}
              width={180}
              value={subcondition.type}
              variant="secondary"
              onChange={(type) => {
                if (type !== ConditionType.Property && type !== ConditionType.ReferenceProperty) {
                  return;
                }

                updateSubcondition(index, {
                  ...subcondition,
                  type,
                  value: null,
                  valueFromColumn: null,
                });
              }}
            />
            <ConditionField
              {...props}
              showSecondaryFilter
              columns={funnelColumns}
              condition={subcondition}
              traits={[]}
              referenceColumns={referenceColumns}
              onChange={(updates) => updateSubcondition(index, updates)}
              onRemove={() => removeSubcondition(index)}
            />
          </Row>
        );
      })}
      <Row pl={10}>
        <DetailButton icon={AddBoxSVG} size="sm" onClick={addSubcondition}>
          Where event property is...
        </DetailButton>
        {!condition.window && (
          <DetailButton
            icon={TimeSVG}
            size="sm"
            onClick={() => {
              onChange({ window: initialFunnelWindow });
            }}
          >
            Time window
          </DetailButton>
        )}
      </Row>
    </>
  );
};
